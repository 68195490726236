import styled$1, { css } from 'styled-components';
import { getProperty, mq, colorSet } from '@ccg/core';
import { forkSpace } from '../../styles/spacing.js';
import { getTransition } from '../../styles/transitions.js';
import { getGrid } from '../Grid/Grid.js';

const StyledTeaser = styled$1.div`
  ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 9'
})};
  ${getTransition}

  .teaserHero__link {
    display: grid;
    ${getGrid({
  default: 6,
  m: 9
})}
    width: 100%;
    min-height: 33vw;
    padding: ${forkSpace(21)} 0;
    align-content: center;
  }

  .teaserHero__content {
    position: relative;
    grid-column: 2 / span 5;

    ${mq('m')} {
      grid-column: 2 / span 5;
    }
  }

  .teaserHero__topic {
    margin-bottom: ${forkSpace(4)};
  }

  .teaserHero__headline {
    margin-bottom: ${forkSpace(4)};

    h2 {
      color: ${colorSet('colorSecondary')};
      display: inline;
    }
  }

  .teaserHero__excerpt {
    max-height: 84px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a:hover .teaserHero__headline h2 {
    color: ${colorSet('color')};
    background: ${colorSet('accent')};
  }

  .teaserHero__tags {
    color: ${colorSet('colorSecondary')};
    margin-bottom: ${forkSpace(2)};
    margin-top: 0;
    grid-column: 2 / span 5;

    ${mq('m')} {
      grid-column: 2 / span 5;
    }
  }

  .teaserHero__media {
    position: absolute;
    justify-self: center;
    width: 100vw;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: ${colorSet('accent')};
    }

    ${mq('m')} {
      width: 100%;
    }

    .teaserHero__image,
    .teaserHero__video {
      width: 100%;
      height: 100%;
    }

    img,
    video {
      display: block;
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  ${({
  topic,
  accentColor
}) => topic && accentColor && css([".teaserHero__media:after{background:", ";}.teaserHero__topic{color:", ";}.icon__icon{background:", ";}a:hover .teaserHero__headline h2{color:", ";background:", ";}"], accentColor.primary, accentColor.primary, accentColor.secondary, accentColor.primary, accentColor.secondary)}
`;

export default StyledTeaser;
