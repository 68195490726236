import styled$1 from 'styled-components';
import { getProperty, mq } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import Grid$1, { getOuterMargins } from '../Grid/Grid.js';

const StyledMainStage = styled$1(Grid$1)`
  position: relative;
  z-index: 0;

  .mainStage__wrapper {
    ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 9'
})}
    ${getOuterMargins}

    ${mq('m')} {
      grid-column-start: 2;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .mainStage__threeDiscription {
    position: absolute;
    bottom: -10%;
    right: 1rem;
  }

  .mainStage__canvas {
    position: relative;
    z-index: 0;
    pointer-events: none;

    @media (max-width: 768px) {
    pointer-events: none;
    touch-action: none;
    }
  }

  h1 {
    margin: ${forkSpace(25)} 0 0;
    transform: translateX(${forkSpace(-6)});
    width: calc(100% + ${forkSpace(12)});
    word-break: normal;

    ${mq('m')} {
      transform: none;
      width: 100%;
    }
  }
`;

export default StyledMainStage;
